import { Link } from "gatsby"
import React from "react"
import CarouselImage from "../../images/banner-main.jpg";

const HeroCarousel = ({ siteTitle }) => (
  <div className="heroCarousel">
      <div className="heroCarousel__slide" style={{backgroundImage: `url("${CarouselImage}")`}} >
        <div className="heroCarousel__slide__content">
            <h1 className="heroCarousel__slide__title">
                Accelerating Your Business
            </h1>
            <p>
                We help boost your Business by helping you focus on what matters the most.
            </p>
            <Link to="/contact" className="button button--primary" >
                Request a meeting
            </Link>
            <Link to="/contact" className="button button--secondary">
                Get in touch
            </Link>
        </div>
      </div>
  </div>
)

export default HeroCarousel
