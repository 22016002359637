import React from 'react';

const CallbackForm = () => (
    <div>
        <form name="callback" method="post" netlify-honeypot="bot-field" data-netlify="true">
            <div className="form-row">
                <div className="form-field">
                    <input type="text" name="name" id="name" placeholder="Name" className="form-input" required/>
                </div>
                <div className="form-field">
                    <input type="email" name="email" id="email" placeholder="Email" className="form-input" required/>
                </div>
            </div>
            <div className="form-field">
                <textarea cols="5" rows="2" name="query" id="query" className="form-input" placeholder="What would you like to dicuss?" required/>
            </div>
            <div className="form-actions">
                <button type="submit" className="button button--primary" >Submit</button>
                <input type="reset" value="Clear" className="button button--clear" />
            </div>
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="callback" />
        </form>
    </div>
)

export default CallbackForm