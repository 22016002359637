import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import HeroCarousel from "../components/hero-carousel/hero-carousel"
import ServiceItem from "../components/services/service"
// import NewsItem from "../components/news/news"
import CallbackForm from "../components/forms/callback-form"
import AboutImage from "../images/about-us.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="RPM Accounting Services" defaultTitleOverride="Accelerating Business" />
    <HeroCarousel />
    {/* About Us */}
    <div className="block__container block__container__light">
      <div className="container">
        <div className="row">
          <div className="col-md-6 mb-4">
            <h2 className="block__title block__title__dark">A Litte bit about us</h2>
            <p>At RPM we're not just another accounting company. We're always aiming to accelerate your business further by making sure we provide you with the customised and personalised solution.</p>
            <p> Here are some of the reason's why you should choose us: </p>
            <ul>
              <li>Excellent track record with 100% satisfied clients providing you with 24/7 support.</li>
              <li>Provide you with timely advice and solution</li>
              <li>We automatically process your bank transactions</li>
            </ul>
            <Link className="button button--primary" to="/about" >Find out more</Link>
          </div>
          <div className="col-md-6">
            <img src={AboutImage} alt="A little about RPM Accounting"/>
          </div>
        </div>
      </div>
    </div>
    {/* Services */}
    <div className="container">
      <div className="block__container">
        <div className="block__header">
          <h2 className="block__title">What We Do</h2>
          <Link to="/services" className="link">View all services</Link>
        </div>
        <div className="services__content row">
          <ServiceItem title={'Bookkeeping / Administration'} cols={4}>
            <p>At RPM, we're focused on providing you with New Zealand's most efficient bookkeeping service. Save your business time and money with our specialised bookkeeping systems and give yourself instant peace of mind knowing your books are meticulously taken care of.</p>
            <p>Let us handle your queries from IRD and set up a manageable payment schedule for you. We will handle your queries like its our own with fast replies and involvement of a member of senior staff in all replies.</p>
          </ServiceItem>
          <ServiceItem title={'Cashflow Forecasting'} cols={4} >
            <p>A cash flow forecast is essential for your business planning. Along with a budget, it's one of the best business tools you can have as it allows you to set financial targets and measure your performance.<br /><br />
           It ensures that you will always have enough money for the things you need and the things that are important to you. Following a budget or spending plan will also keep you out of debt or help you work your way out of debt if you are currently in debt.</p>
          </ServiceItem>
          <ServiceItem title={'Annual Accounting'} cols={4} >
            <p>Monthly, quarterly or annual accounts which provide essential information on the financial health of your business, so that you can focus on what you do best.</p>
            <ul>
              <li>Prepare financial Statements-Balance sheet, Profit and loss and annual accounts.</li>
              <li>File with IRD-helping you manage your Income tax obligations with our favourite IRD.</li>
              <li>Review your Accounts- We will review your  annual accounts via your xero account and bank statements</li>
            </ul>
          </ServiceItem>        </div>
      </div>
    </div>

    {/* CTA */}
    <div className="block__container__dark2">
      <div className="container">
        <div className="callback__content d-flex justify-content-between flex-wrap align-items-lg-center">
          <h4 className="block__title__light block__title__nomargin">Let's help you take your business to the next level.</h4>
          <Link to="/contact" className="button button--secondary">Request a callback</Link>
        </div>
      </div>
    </div>
    {/* Company News / Blog */}
    {/* <div className="container">
      <div className="block__container">
        <div className="block__header">
          <h2 className="block__title">News &amp; Updates</h2>
          <Link to="/news" className="link">View all news</Link>
        </div>
        <div className="services__content row">
          <NewsItem title={'News Item 1'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
          <NewsItem title={'News Item 2'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
          <NewsItem title={'News Item 3'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
          <NewsItem title={'News Item 4'} content={'Adipiscing elit, sed do eiusmod tempor inciunt ut labore et dolore magna liqua.abore et dolore incididunt ut labore et dolore magna liqua. abore et dolore'} />
        </div>
      </div>
    </div> */}
    {/* Testimonials */}

    {/* Request a callback form */}
    <div className="block__container__dark1 margin__offset">
      <div className="container">
        <div className="callback__content row">
          <div className="col-md-6">
            <h2 className="block__title block__title__underline">Let's Talk</h2>
            <p>
              Please fill in your details and one our team member will give you a call back.
            </p>
          </div>
          <div className="col-md-6">
            <CallbackForm />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
